<template>
  <div class="content">
    <h1>{{ form.title }}</h1>
    <h4 v-html="form.content"></h4>
  </div>
</template>

<script>
export default {
  name: "ViewTNC",
  data() {
    return {
      form: {
        title: "",
        content: "<h2>I am Example</h2>",
      },
    };
  },

  methods: {
    initData() {
      try {
        const pageSlug = this.$route.path.split("/")[2];
        this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/page/slug/${pageSlug}`
          )
          .then(({ data }) => {
            this.form = {
              title: data.title,
              content: data.content,
            };
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style lang="scss">
.content {
  padding: 16px 32px;
}
</style>
